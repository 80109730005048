<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">Pré alerte inondation</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <b-form-group label="Type de compte">
                <validation-provider
                  #default="{ errors }"
                  name="role"
                  vid="role"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="webUser.role"
                    :options="options"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                    class="demo-inline-spacing"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- name -->
              <b-form-group label="Nom">
                <validation-provider
                  #default="{ errors }"
                  name="Nom"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="webUser.lastName"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- name -->
              <b-form-group label="Prénom">
                <validation-provider
                  #default="{ errors }"
                  name="prénom"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="webUser.firstName"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Zone -->
              <b-form-group label="Zone" v-if="webUser.role === 'POINTFOCAL'">
                <validation-provider
                  #default="{ errors }"
                  name="Zone"
                  vid="zone"
                  rules="required"
                >
                  <b-form-select
                    v-model="webUser.zone"
                    :options="zones"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Phone -->
              <b-form-group label="Télephone">
                <validation-provider
                  #default="{ errors }"
                  name="Télephone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    v-model="webUser.phone"
                    name="phone"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="webUser.email"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label="Mot de passe">
                <validation-provider
                  #default="{ errors }"
                  name="Mot de passe"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="webUser.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Je suis d'accord pour notre
                  <b-link>politique de confidentialité et conditions</b-link>
                </b-form-checkbox>
              </b-form-group> -->

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                S'inscrire
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Vous avez déjà un compte ?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Se connecter à la place</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BFormSelect,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
// import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      options: [
        { item: "ANPC", name: "ANPC" },
        { item: "POINTFOCAL", name: "POINTFOCAL" },
      ],
      webUser: {
        email: "",
        firstName: "",
        lastName: "",
        zone: null,
        phone: "",
        password: "",
        role: "ANPC",
      },
      zones: [{ value: null, text: "Veuillez sélectionner une option" }],
      status: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.$store
      .dispatch("mainAppStore/fetchZonesListItems")
      .then((response) => {
        console.log(response.data);
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < response.data.length; index++) {
          const zone = {
            value: response.data[index].id,
            text: response.data[index].name,
          };
          this.zones.push(zone);
        }
      })
      .catch((err) => {
        console.error(err.response.headers);
      });
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          console.log(this.student);
          this.$store
            .dispatch("authStore/registerUser", this.webUser)
            .then((response) => {
              console.log(response.data);
              this.$swal({
                title: "Succes",
                html: "Le compte a bien été creer",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });
              this.$router.push({ name: "login" });
            })
            .catch((err) => {
              this.$swal({
                title: "Erreur",
                html: err.response.data.message,
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
              console.error(err);
            });

          // useJwt.register({
          //   username: this.username,
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)
          //     this.$router.push('/')
          //   })
          //   .catch(error => {
          //     this.$refs.registerForm.setErrors(error.response.data.error)
          //   })
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
